@import url('https://fonts.googleapis.com/css?family=Titillium+Web:300,400,600,700');
* {
    box-sizing: border-box;
}

html {
    font-size: 1.000em;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    height: 100%;
    margin: 0;
    background-color: #fff;
    color: #595959;
    font-family: 'Titillium Web', sans-serif;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
    display: block;
}

h1,
h2,
h3,
p,
ul,
ol {
    margin-top: 0;
}

h1,
h2,
h3 {
    color: #6a392a;
    text-transform: uppercase;
    font-weight: 600;
    .footerContent & {
        color: #fff;
    }
}

h1 {
    font-size: 36px;
    @media (max-width: 767px) {
        font-size: 30px;
    }
    @media (max-width: 767px) {
        font-size: 24px;
    }
}

h2 {
    font-size: 36px;
}

h3 {
    margin-bottom: 10px;
    font-size: 24px;
}

strong {
    font-weight: 600;
}

img {
    display: inline-block;
    border: 0;
    max-width: 100%;
    vertical-align: top;
}

p {
    margin-bottom: 22px;
    font-size: 16px;
    line-height: 22px;
    a {
        color: inherit;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}

.noMargin {
    margin: 0!important;
}

.marginExp {
    margin: 0 -45px;
}

.noBorder {
    border: 0!important;
}

.textCenter {
    text-align: center;
}

.textRight {
    text-align: right;
}

.pageButton {
    display: inline-block;
    padding: 0 57px;
    height: 80px;
    line-height: 80px;
    color: #fff;
    background: #6a392a;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    @media (max-width: 767px) {
        padding: 0 35px;
        height: 70px;
        line-height: 70px;
        font-size: 20px;
    }
    @media (max-width: 479px) {
        padding: 0 20px;
        height: 60px;
        line-height: 60px;
        font-size: 16px;
    }
}

.clearLine {
    clear: both;
    &.line22 {
        height: 22px;
    }
    &.line50 {
        height: 50px;
    }
}

.contentBox {
    position: relative;
    margin: 0 auto;
    max-width: 1200px;
}

.extBox {
    margin: 0 -15px;
    @media (max-width: 1249px) {
        h3, p {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}

.col3item {
    float: left;
    padding: 0 15px;
    width: 33.33%;
    @media (max-width: 991px) {
        width: 50%;
    }
    @media (max-width: 767px) {
        width: 100%;
    }
}

.col2item {
    float: left;
    width: 50%;
    @media (max-width: 992px) {
        width: 100%;
    }
}

#pageBox {
    overflow: hidden;
}

#pageHeader {
    padding: 16px 0 0 0;
    @media (max-width: 991px) {
        padding: 0;
    }
    #headerLogo {
        float: left;
        display: block;
        width: 150px;
        height: 150px;
        background: url(../img/zeltner_hausverwaltung_logo.svg) no-repeat center center;
        background-size: contain;
        font-size: 0;
        @media (max-width: 1249px) {
            margin-left: 15px;
        }
        @media (max-width: 991px) {
            float: none;
            width: 120px;
            height: 120px;
            margin: 25px auto 10px auto;
        }
    }
    #headerContact {
        float: right;
        margin-top: 20px;
        padding: 0 0 0 55px;
        background: url(../img/icon_phone.svg) no-repeat center left;
        background-size: 50px;
        color: #6a392a;
        font-size: 20px;
        text-align: right;
        @media (max-width: 991px) {
            float: none;
            margin: 0;
            padding: 15px;
            background: #6a392a;
            color: #fff;
            text-align: center;
        }
        @media (max-width: 479px) {
            font-size: 12px!important;
        }
        @media (max-width: 1249px) {
            padding-right: 15px;
        }
        strong {
            display: block;
            font-size: 23px;
            text-transform: uppercase;
            @media (max-width: 991px) {
                display: inline-block;
                padding-right: 20px;
                font-size: 20px;
            }
            @media (max-width: 479px) {
                font-size: 12px;
            }
        }
        span {
            color: #595959;
            @media (max-width: 991px) {
                color: #fff;
            }
        }
    }
    #headerNav {
        float: right;
        margin: 20px 0 0 0;
        padding: 0;
        text-align: right;
        @media (max-width: 1249px) {
            padding-right: 15px;
        }
        @media (max-width: 991px) {
            padding: 0 15px;
            text-align: center;
        }
        ul {
            margin: 0;
            padding: 0;
            li {
                display: inline-block;
                margin-left: 35px;
                &:first-child {
                    margin: 0;
                }
                @media (max-width: 991px) {
                    margin: 0 17px!important;
                }
                a {
                    color: #6a392a;
                    font-size: 20px;
                    text-decoration: none;
                    text-transform: uppercase;
                    font-weight: 600;
                }
            }
        }
    }
    #headerImg {
        position: relative;
        margin: 16px 0 53px 0;
        width: 100%;
        padding: 0 40px 35% 40px;
        max-height: 420px;
        background: url(../img/zeltner_hausverwaltung_header.jpg) no-repeat center top;
        background-size: cover;
        color: #fff;
        text-align: left;
        p {
            line-height: 48px;
            font-size: 36px;
            text-transform: uppercase;
            @media (max-width: 767px) {
                line-height: 42px;
                font-size: 30px;
            }
            @media (max-width: 767px) {
                line-height: 36px;
                font-size: 24px;
            }
        }
        h1 {
            display: block;
            margin: 0;
            padding: 46px 0 15px 0;
            color: #fff;
            font-weight: 600;
        }
        span {
            display: block;
            font-weight: 300;
        }
    }
}

#pageFooter {
    width: 100%;
    background: #6a392a;
    color: #fff;
    line-height: 48px;
    @media (max-width: 399px) {
        text-align: center;
    }
    a {
        color: #fff;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
        @media (max-width: 399px) {
            display: block;
        }
    }
    span {
        display: inline-block;
        padding: 0 15px;
        @media (max-width: 399px) {
            display: none;
        }
    }
    .contentBox {
        padding: 0 15px;
    }
}

.footerContent {
    section {
        display: none;
        text-align: left;
    }
}

#pageContent {
    text-align: left;
    .backgroundBox {
        padding: 45px 0;
    }
    .partService {
        margin-bottom: 44px;
        background: url(../img/unsere_leistungen.png) no-repeat center top;
        background-size: cover;
    }
    .revertItem {
        position: relative;
        margin: 0 20px 30px 20px;
        height: 270px;
        perspective: 2000px;
        -webkit-perspective: 2000px;
        @media (max-width: 1199px) {
            height: 320px;
        }
        @media (max-width: 991px) {
            height: 250px;
        }
        @media (max-width: 767px) {
            height: 300px;
        }
        @media (max-width: 479px) {
            height: 350px;
        }
        @media (max-width: 379px) {
            height: 400px;
        }
        .frontPart {
            position: absolute;
            padding: 42px 75px 0 75px;
            border: 1px solid #616161;
            height: 100%;
            width: 100%;
            color: #616161;
            z-index: 1;
            backface-visibility: hidden;
            transform-style: preserve-3d;
            transition: all 0.5s ease-out 0s;
            transform: rotateY(0deg);
            @media (max-width: 767px) {
                padding: 42px 30px 0 30px;
            }
            @media (max-width: 479px) {
                padding: 42px 15px 0 15px;
            }
            strong {
                position: relative;
                display: block;
                padding: 138px 0 0 0;
                font-size: 24px;
                text-transform: uppercase;
                @media (max-width: 767px) {
                    font-size: 20px;
                }
                @media (max-width: 479px) {
                    font-size: 16px;
                }
                &::before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 96px;
                    background-position: center top;
                    background-repeat: no-repeat;
                    background-size: contain;
                    content: "";
                }
            }
        }
        &.item1 .frontPart strong::before {
            background-image: url(../img/icon_leistungsumfang.svg);
        }
        &.item2 .frontPart strong::before {
            background-image: url(../img/icon_weg_verwaltungen.svg);
        }
        &.item3 .frontPart strong::before {
            background-image: url(../img/icon_mietverwaltung.svg);
        }
        &.item4 .frontPart strong::before {
            background-image: url(../img/icon_vermoegensverwaltung.svg);
        }
        .backPart {
            position: absolute;
            height: 100%;
            width: 100%;
            background: #616161;
            color: #fff;
            z-index: 1;
            backface-visibility: hidden;
            transform-style: preserve-3d;
            transition: all 0.5s ease-out 0s;
            transform: rotateY(-180deg);
            p {
                padding: 0 75px;
                @media (max-width: 767px) {
                    padding: 0 30px;
                }
                @media (max-width: 479px) {
                    padding: 0 15px;
                }
            }
        }
        &:hover .frontPart {
            transform: rotateY(-180deg);
        }
        &:hover .backPart {
            transform: rotateY(0deg);
        }
        h3 {
            position: relative;
            margin-bottom: 25px;
            padding: 25px 80px 0 80px;
            display: inline-block;
            color: #fff;
            font-weight: 600;
            @media (max-width: 767px) {
                font-size: 20px;
            }
            @media (max-width: 479px) {
                font-size: 16px;
            }
            &::before {
                position: absolute;
                top: 20px;
                left: 10px;
                width: 60px;
                height: 40px;
                background-position: center top;
                background-repeat: no-repeat;
                background-size: contain;
                content: "";
            }
        }
        &.item1 .backPart h3::before {
            background-image: url(../img/icon_leistungsumfang_weiss.svg);
        }
        &.item2 .backPart h3::before {
            background-image: url(../img/icon_weg_verwaltungen_weiss.svg);
        }
        &.item3 .backPart h3::before {
            background-image: url(../img/icon_mietverwaltung_weiss.svg);
        }
        &.item4 .backPart h3::before {
            background-image: url(../img/icon_vermoegensverwaltung_weiss.svg);
        }
    }
    .partTeam {
        position: relative;
        padding-bottom: 0;
        background: #ebebeb;
        &::before {
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 0;
            border-left: calc(1200px * 0.15) solid transparent;
            border-right: calc(1200px * 0.85) solid transparent;
            border-top: 22px solid #fff;
            content: "";
        }
        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 0;
            border-left: calc(1200px * 0.85) solid transparent;
            border-right: calc(1200px * 0.15) solid transparent;
            border-bottom: 22px solid #fff;
            content: "";
        }
        h2 {
            padding-bottom: 16px;
        }
        strong {
            display: inline-block;
            color: #6a392a;
            line-height: 38px;
            font-size: 24px;
            text-transform: uppercase;
        }
        img {
            display: block;
            margin: 0 auto 28px auto;
        }
        span {
            display: inline-block;
            line-height: 38px;
        }
        .col3item {
            padding-bottom: 38px;
        }
        @media (min-width: 992px) {
            .col3item:nth-child(3n+2) {
                clear: left;
            }
        }
        @media (max-width: 991px) {
            .col3item:nth-child(2n+2) {
                clear: left;
            }
        }
        @media (max-width: 767px) {
            .col3item {
                clear: left;
            }
        }
    }
    .partContact {
        padding: 44px 0 38px 0;
        h2 {
            padding-bottom: 10px;
        }
        strong {
            display: block;
        }
    }
    .refSlider {
        margin-bottom: 60px;
        h2 {
            padding-bottom: 16px;
        }
        .swiper-slide {
            width: 478px;
            opacity: 0.3;
            transition: opacity 0.3 ease-out;
            @media (max-width: 659px) {
                opacity: 1;
                width: calc(100vw - 120px);
            }
            @media (max-width: 479px) {
                width: calc(100vw - 60px);
            }
            &.swiper-slide-active {
                opacity: 1;
                .slideShadow,
                .slideCaption {
                    opacity: 1;
                }
            }
            .slideShadow {
                margin: 0 auto;
                height: 30px;
                width: 95%;
                -webkit-border-radius: 50%;
                border-radius: 50%;
                background: #333;
                opacity: 0.3;
                -webkit-filter: blur(20px);
                filter: blur(20px);
                -webkit-transform: scale(1, 0.3);
                transform: scale(1, 0.3);
                opacity: 0;
                @media (max-width: 767px) {
                    width: 80%;
                }
                @media (max-width: 767px) {
                    width: 70%;
                }
            }
            .slideCaption {
                text-align: center;
                padding: 20px 15px 0 15px;
                line-height: 40px;
                color: #6a392a;
                font-size: 24px;
                text-transform: uppercase;
                font-weight: 600;
                opacity: 0;
                @media (max-width: 767px) {
                    font-size: 20px;
                    line-height: 30px;
                }
            }
        }
    }
}


/**
 * Swiper 3.3.1
 * Most modern mobile touch slider and framework with hardware accelerated transitions
 * 
 * http://www.idangero.us/swiper/
 * 
 * Copyright 2016, Vladimir Kharlampidi
 * The iDangero.us
 * http://www.idangero.us/
 * 
 * Licensed under MIT
 * 
 * Released on: February 7, 2016
 */

.swiper-container {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    height: 100%;
    /* Fix of Webkit flickering */
    z-index: 1;
}

.swiper-nav {
    position: absolute;
    bottom: 30px;
    left: 15px;
    height: 22px;
    width: 100%;
    z-index: 30;
    @media only screen and (max-width: 1199px) {
        bottom: 40px;
    }
}

.swiper-container-no-flexbox .swiper-slide {
    float: left;
}

.swiper-container-vertical>.swiper-wrapper {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
}

.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
    -ms-transition-property: -ms-transform;
    transition-property: transform;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    -o-transform: translate(0px, 0px);
    -ms-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
}

.swiper-container-multirow>.swiper-wrapper {
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}

.swiper-container-free-mode>.swiper-wrapper {
    -webkit-transition-timing-function: ease-out;
    -moz-transition-timing-function: ease-out;
    -ms-transition-timing-function: ease-out;
    -o-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    margin: 0 auto;
}

.swiper-slide {
    -webkit-flex-shrink: 0;
    -ms-flex: 0 0 auto;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
}


/* Auto Height */

.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
    height: auto;
}

.swiper-container-autoheight .swiper-wrapper {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-transition-property: -webkit-transform, height;
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
    -ms-transition-property: -ms-transform;
    transition-property: transform, height;
}


/* a11y */

.swiper-container .swiper-notification {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    opacity: 0;
    z-index: -1000;
}


/* IE10 Windows Phone 8 Fixes */

.swiper-wp8-horizontal {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}

.swiper-wp8-vertical {
    -ms-touch-action: pan-x;
    touch-action: pan-x;
}


/* Arrows */

.swiper-button-prev,
.swiper-button-next {
    position: absolute;
    top: 95px;
    left: 0;
    width: 64px;
    height: 64px;
    z-index: 10;
    cursor: pointer;
    @media (max-width: 659px) {
        top: 0;
        left: 0;
        width: 60px;
        height: calc((100vw - 120px) / 1.88);
        background: #fff;
    }
    @media (max-width: 479px) {
        width: 30px;
        height: calc((100vw - 60px) / 1.88);
    }
}

.swiper-button-next {
    left: auto;
    right: 0;
    @media (max-width: 659px) {
        right: 0;
    }
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {}

.swiper-button-prev::before,
.swiper-button-prev::after,
.swiper-button-next::before,
.swiper-button-next::after {
    width: 50px;
    height: 6px;
    background: #595758;
    position: absolute;
    content: "";
    @media (max-width: 659px) {
        width: 20px;
        height: 3px;
    }
}

.swiper-button-prev::before {
    left: 11px;
    top: 13px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    @media (max-width: 659px) {
        left: 5px;
        top: 50%;
        margin-top: -8px;
    }
}

.swiper-button-prev::after {
    left: 11px;
    bottom: 14px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    @media (max-width: 659px) {
        left: 5px;
        bottom: 50%;
        margin-bottom: -8px;
    }
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {}

.swiper-button-next::before {
    right: 11px;
    top: 13px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    @media (max-width: 659px) {
        right: 5px;
        top: 50%;
        margin-top: -8px;
    }
}

.swiper-button-next::after {
    right: 11px;
    bottom: 14px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    @media (max-width: 659px) {
        right: 5px;
        bottom: 50%;
        margin-bottom: -8px;
    }
}


/* Pagination Styles */

.swiper-pagination {
    float: left;
    text-align: center;
    -webkit-transition: 300ms;
    -moz-transition: 300ms;
    -o-transition: 300ms;
    transition: 300ms;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    z-index: 10;
}

.swiper-pagination.swiper-pagination-hidden {
    opacity: 0;
}


/* Common Styles */

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal>.swiper-pagination-bullets {}


/* Bullets */

.swiper-pagination-bullet {
    width: 8px;
    height: 22px;
    line-height: 22px;
    margin: 0 4px;
    display: inline-block;
    border-radius: 100%;
    background: transparent;
    opacity: 1;
    font-size: 19px;
}

button.swiper-pagination-bullet {
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer;
}

.swiper-pagination-white .swiper-pagination-bullet {
    background: transparent;
}

.swiper-pagination-bullet-active {
    opacity: 1;
    background: transparent;
    text-decoration: underline;
}

.swiper-pagination-white .swiper-pagination-bullet-active {
    background: #fff;
}

.swiper-pagination-black .swiper-pagination-bullet-active {
    background: #000;
}

.swiper-container-vertical>.swiper-pagination-bullets {
    right: 10px;
    top: 50%;
    -webkit-transform: translate3d(0px, -50%, 0);
    -moz-transform: translate3d(0px, -50%, 0);
    -o-transform: translate(0px, -50%);
    -ms-transform: translate3d(0px, -50%, 0);
    transform: translate3d(0px, -50%, 0);
}

.swiper-container-vertical>.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 5px 0;
    display: block;
}

.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 5px;
}


/* Progress */

.swiper-pagination-progress {
    background: rgba(0, 0, 0, 0.25);
    position: absolute;
}

.swiper-pagination-progress .swiper-pagination-progressbar {
    background: #007aff;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: left top;
    -moz-transform-origin: left top;
    -ms-transform-origin: left top;
    -o-transform-origin: left top;
    transform-origin: left top;
}

.swiper-container-rtl .swiper-pagination-progress .swiper-pagination-progressbar {
    -webkit-transform-origin: right top;
    -moz-transform-origin: right top;
    -ms-transform-origin: right top;
    -o-transform-origin: right top;
    transform-origin: right top;
}

.swiper-container-horizontal>.swiper-pagination-progress {
    width: 100%;
    height: 4px;
    left: 0;
    top: 0;
}

.swiper-container-vertical>.swiper-pagination-progress {
    width: 4px;
    height: 100%;
    left: 0;
    top: 0;
}

.swiper-pagination-progress.swiper-pagination-white {
    background: rgba(255, 255, 255, 0.5);
}

.swiper-pagination-progress.swiper-pagination-white .swiper-pagination-progressbar {
    background: #fff;
}

.swiper-pagination-progress.swiper-pagination-black .swiper-pagination-progressbar {
    background: #000;
}


/* 3D Container */

.swiper-container-3d {
    -webkit-perspective: 1200px;
    -moz-perspective: 1200px;
    -o-perspective: 1200px;
    perspective: 1200px;
}

.swiper-container-3d .swiper-wrapper,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-cube-shadow {
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 10;
}

.swiper-container-3d .swiper-slide-shadow-left {
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
    /* Safari 4+, Chrome */
    background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Chrome 10+, Safari 5.1+, iOS 5+ */
    background-image: -moz-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Firefox 3.6-15 */
    background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Opera 11.10-12.00 */
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Firefox 16+, IE10, Opera 12.50+ */
}

.swiper-container-3d .swiper-slide-shadow-right {
    background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
    /* Safari 4+, Chrome */
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Chrome 10+, Safari 5.1+, iOS 5+ */
    background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Firefox 3.6-15 */
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Opera 11.10-12.00 */
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Firefox 16+, IE10, Opera 12.50+ */
}

.swiper-container-3d .swiper-slide-shadow-top {
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
    /* Safari 4+, Chrome */
    background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Chrome 10+, Safari 5.1+, iOS 5+ */
    background-image: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Firefox 3.6-15 */
    background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Opera 11.10-12.00 */
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Firefox 16+, IE10, Opera 12.50+ */
}

.swiper-container-3d .swiper-slide-shadow-bottom {
    background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
    /* Safari 4+, Chrome */
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Chrome 10+, Safari 5.1+, iOS 5+ */
    background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Firefox 3.6-15 */
    background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Opera 11.10-12.00 */
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Firefox 16+, IE10, Opera 12.50+ */
}


/* Coverflow */

.swiper-container-coverflow .swiper-wrapper,
.swiper-container-flip .swiper-wrapper {
    /* Windows 8 IE 10 fix */
    -ms-perspective: 1200px;
}


/* Cube + Flip */

.swiper-container-cube,
.swiper-container-flip {
    overflow: visible;
}

.swiper-container-cube .swiper-slide,
.swiper-container-flip .swiper-slide {
    pointer-events: none;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 1;
}

.swiper-container-cube .swiper-slide .swiper-slide,
.swiper-container-flip .swiper-slide .swiper-slide {
    pointer-events: none;
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-flip .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
    pointer-events: auto;
}

.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-flip .swiper-slide-shadow-top,
.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-right {
    z-index: 0;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
}


/* Cube */

.swiper-container-cube .swiper-slide {
    visibility: hidden;
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    width: 100%;
    height: 100%;
}

.swiper-container-cube.swiper-container-rtl .swiper-slide {
    -webkit-transform-origin: 100% 0;
    -moz-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-prev,
.swiper-container-cube .swiper-slide-next+.swiper-slide {
    pointer-events: auto;
    visibility: visible;
}

.swiper-container-cube .swiper-cube-shadow {
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.6;
    -webkit-filter: blur(50px);
    filter: blur(50px);
    z-index: 0;
}


/* Fade */

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
    -webkit-transition-timing-function: ease-out;
    -moz-transition-timing-function: ease-out;
    -ms-transition-timing-function: ease-out;
    -o-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.swiper-container-fade .swiper-slide {
    pointer-events: none;
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity;
}

.swiper-container-fade .swiper-slide .swiper-slide {
    pointer-events: none;
}

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
    pointer-events: auto;
}


/* Scrollbar */

.swiper-scrollbar {
    border-radius: 10px;
    position: relative;
    -ms-touch-action: none;
    background: rgba(0, 0, 0, 0.1);
}

.swiper-container-horizontal>.swiper-scrollbar {
    position: absolute;
    left: 1%;
    bottom: 3px;
    z-index: 50;
    height: 5px;
    width: 98%;
}

.swiper-container-vertical>.swiper-scrollbar {
    position: absolute;
    right: 3px;
    top: 1%;
    z-index: 50;
    width: 5px;
    height: 98%;
}

.swiper-scrollbar-drag {
    height: 100%;
    width: 100%;
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    left: 0;
    top: 0;
}

.swiper-scrollbar-cursor-drag {
    cursor: move;
}


/* Preloader */

.swiper-lazy-preloader {
    width: 42px;
    height: 42px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -21px;
    margin-top: -21px;
    z-index: 10;
    -webkit-transform-origin: 50%;
    -moz-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
    -moz-animation: swiper-preloader-spin 1s steps(12, end) infinite;
    animation: swiper-preloader-spin 1s steps(12, end) infinite;
}

.swiper-lazy-preloader:after {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
    background-position: 50%;
    -webkit-background-size: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
}

.swiper-lazy-preloader-white:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

@-webkit-keyframes swiper-preloader-spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes swiper-preloader-spin {
    100% {
        transform: rotate(360deg);
    }
}